import { CLIENT_ADMIN } from "@ems/constants";
import Routes from "./Routes.vue";
import {
  SETTING_INFORMATION,
  DASHBOARD,
  USER,
  PROFILE,
  RENEWABLE_OPTIONS,
  FACILITIES_MONITORING,
} from "@ems/constants";

const view =
  (name: string) =>
  (PERMISSION = CLIENT_ADMIN) =>
    import(
      /* webpackChunkName: "[request]" */ `@ems/pages/${PERMISSION}/${name}.vue`
    );

export default [
  {
    path: DASHBOARD.path,
    name: DASHBOARD.name,
    component: Routes,
    icon: "dashboard",
    children: [
      {
        path: DASHBOARD.path,
        name: DASHBOARD.name,
        component: view("Dashboard/index"),
      },
      {
        path: DASHBOARD.SUSTAINABILITY_ACHIEVEMENTS.path,
        name: DASHBOARD.SUSTAINABILITY_ACHIEVEMENTS.name,
        component: view("Dashboard/SA"),
      },
      {
        path: DASHBOARD.PERFORMANCE_OVERVIEW.path,
        name: DASHBOARD.PERFORMANCE_OVERVIEW.name,
        component: view("Dashboard/PO"),
      },
      // { Move from child of dashboard to main routes
      //   path: DASHBOARD.FACILITIES_MONITORING.path,
      //   name: DASHBOARD.FACILITIES_MONITORING.name,
      //   component: Routes,
      //   children: [
      //     {
      //       path: "",
      //       name: DASHBOARD.FACILITIES_MONITORING.name,
      //       component: view("Dashboard/FM/index"),
      //     },
      //     {
      //       path: `${DASHBOARD.FACILITIES_MONITORING.FACILITIES_MONITORING_ID.path}`,
      //       name: DASHBOARD.FACILITIES_MONITORING.FACILITIES_MONITORING_ID.name,
      //       component: view("Dashboard/FM/[facility_id]"),
      //     },
      //   ],
      // },
    ],
  },
  {
    path: FACILITIES_MONITORING.path,
    name: FACILITIES_MONITORING.name,
    component: Routes,
    icon: "monitoring",
    children: [
      {
        path: "",
        name: FACILITIES_MONITORING.name,
        component: view("Dashboard/FM/index"),
      },
      {
        path: `${FACILITIES_MONITORING.FACILITIES_MONITORING_ID.path}`,
        name: FACILITIES_MONITORING.FACILITIES_MONITORING_ID.name,
        component: view("Dashboard/FM/[facility_id]"),
      },
    ],
  },
  {
    path: SETTING_INFORMATION.path,
    name: SETTING_INFORMATION.name,
    component: Routes,
    icon: "setting-info",
    children: [
      {
        path: "",
        name: SETTING_INFORMATION.name,
        component: view("SettingInformation/index"),
      },
      {
        path: SETTING_INFORMATION.SCOPE_1_AND_3.path,
        name: SETTING_INFORMATION.SCOPE_1_AND_3.name,
        component: view("SettingInformation/S13E/index"),
      },
      {
        path: SETTING_INFORMATION.SCOPE13E_INPUT_MANUAL.path,
        name: SETTING_INFORMATION.SCOPE13E_INPUT_MANUAL.name,
        component: view("SettingInformation/S13E/InputDataManual"),
      },
      {
        path: SETTING_INFORMATION.SCOPE_2_RENEWABLES_PERFORMMANACE.path,
        name: SETTING_INFORMATION.SCOPE_2_RENEWABLES_PERFORMMANACE.name,
        component: view("SettingInformation/S2RP/index"),
      },
      {
        path: SETTING_INFORMATION.SCOPE_2_INPUT_PPA.path,
        name: SETTING_INFORMATION.SCOPE_2_INPUT_PPA.name,
        component: view("SettingInformation/S2RP/InputPPA_RECs"),
      },
      {
        path: SETTING_INFORMATION.S2RP_INPUT_MANUAL.path,
        name: SETTING_INFORMATION.S2RP_INPUT_MANUAL.name,
        component: view("SettingInformation/S2RP/InputDataManual"),
      },
      {
        path: SETTING_INFORMATION.SCOPE_2_EMISSION_TARGETS.path,
        name: SETTING_INFORMATION.SCOPE_2_EMISSION_TARGETS.name,
        component: view("SettingInformation/CET"),
      },
      {
        path: SETTING_INFORMATION.YOUR_GREEN_OPTIONS.path,
        name: SETTING_INFORMATION.YOUR_GREEN_OPTIONS.name,
        component: Routes,
        children: [
          {
            path: "",
            name: SETTING_INFORMATION.YOUR_GREEN_OPTIONS.name,
            component: view("SettingInformation/YGO/index"),
          },
        ],
      },
      {
        path: `${SETTING_INFORMATION.YOUR_GREEN_OPTIONS.path}/${SETTING_INFORMATION.YOUR_GREEN_OPTIONS.CONTACT_SJ.path}`,
        name: SETTING_INFORMATION.YOUR_GREEN_OPTIONS.CONTACT_SJ.name,
        component: view("SettingInformation/YGO/ContactSJ"),
      },
      {
        path: SETTING_INFORMATION.SETTING_BASELINE.path,
        name: SETTING_INFORMATION.SETTING_BASELINE.name,
        component: view("SettingInformation/SettingBaseline/index"),
      },
    ],
  },
  {
    path: RENEWABLE_OPTIONS.path,
    name: RENEWABLE_OPTIONS.name,
    component: Routes,
    icon: "Renewable",
    children: [
      {
        path: "",
        name: RENEWABLE_OPTIONS.name,
        component: view("RenewableOptions/index"),
      },
      {
        path: RENEWABLE_OPTIONS.BUY_RECS.path,
        name: RENEWABLE_OPTIONS.BUY_RECS.name,
        component: view("RenewableOptions/BuyRECs"),
      },
    ],
  },
  // {
  //   path: "customer/create",
  //   name: "Add New Customer",
  //   component: view("Customer/Create"),
  //   icon: "home_plus",
  // },
  // {Hide for Feed back
  //   path: USER.USER_CREATE.path,
  //   name: USER.USER_CREATE.name,
  //   component: view("User/Create"),
  //   icon: "user_plus",
  // },
  // {
  //   path: USER.USER_OVERVIEW.path,
  //   name: USER.USER_OVERVIEW.name,
  //   component: view("User/index"),
  //   icon: "user_management",
  // },
  {
    path: USER.USER_OVERVIEW.path,
    component: Routes,
    icon: "user_management",
    children: [
      {
        path: "",
        name: USER.USER_OVERVIEW.name,
        component: view("User/index"),
      },
      {
        path: USER.USER_CREATE.path,
        name: USER.USER_CREATE.name,
        component: view("User/CRUDUser"),
      },
      {
        path: ":UserId/edit",
        name: "Edit User",
        component: view("User/DetailEditUser"),
      },
    ],
  },
  {
    path: PROFILE.path,
    name: PROFILE.name,
    component: view("Profile"),
    icon: "profile",
  },
];
