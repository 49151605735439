export const MONTHS = [
  {
    MonthName: "Jan",
    MonthFullName: "January",
  },
  {
    MonthName: "Feb",
    MonthFullName: "February",
  },
  {
    MonthName: "Mar",
    MonthFullName: "March",
  },
  {
    MonthName: "Apr",
    MonthFullName: "April",
  },
  {
    MonthName: "May",
    MonthFullName: "May",
  },
  {
    MonthName: "Jun",
    MonthFullName: "June",
  },
  {
    MonthName: "Jul",
    MonthFullName: "July",
  },
  {
    MonthName: "Aug",
    MonthFullName: "August",
  },
  {
    MonthName: "Sep",
    MonthFullName: "September",
  },
  {
    MonthName: "Oct",
    MonthFullName: "October",
  },
  {
    MonthName: "Nov",
    MonthFullName: "November",
  },
  {
    MonthName: "Dec",
    MonthFullName: "December",
  },
];
export const MONTHS_SCOPE1_SCOPE3 = [
  {
    MonthName: "Jan",
    MonthFullName: "January",
  },
];

export const PREVIOUS_YEAR_RANGE_FILTER = 4;

export const CURRENT_YEAR = new Date().getFullYear();
