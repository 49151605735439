export const SETTING_INFORMATION = {
  path: "setting-information",
  name: "Setting Information",

  SCOPE_1_AND_3: {
    path: "scope-1-and-scope-3-emissions",
    name: "Scope 1 and Scope 3 Emissions",
  },
  SCOPE13E_INPUT_MANUAL: {
    path: "scope-1-and-scope-3-emissions/input-data-manual",
    name: "S13E Input Data Manual",
  },
  SCOPE_2_RENEWABLES_PERFORMMANACE: {
    path: "scope-2-renewables-performance",
    name: "Scope 2 Renewables Performance",
  },
  SCOPE_2_INPUT_PPA: {
    path: "scope-2-renewables-performance/input-PPA-and-RECs",
    name: "Input PPA and RECs",
  },
  S2RP_INPUT_MANUAL: {
    path: "scope-2-renewables-performance/input-data-manual",
    name: "S2RP Input Data Manual",
  },
  SCOPE_2_EMISSION_TARGETS: {
    path: "scope-2-emission-targets",
    name: "Scope 2 Emission Targets",
  },
  YOUR_GREEN_OPTIONS: {
    path: "your-green-options",
    name: "Your Green Options",
    CONTACT_SJ: {
      path: "contact-sj",
      name: "Contact SJ",
    },
    SELECT_STANDARD: {
      path: "select-standard",
      name: "Select Standard",
      BUY_RECS: {
        path: "buy-recs",
        name: "Buy RECs",
      },
      BUY_ORDER: {
        path: "buy-order",
        name: "Buy Order",
      },
    },
  },
  SETTING_BASELINE: {
    path: "setting-baseline",
    name: "Setting Baseline",
  },
};

export const DASHBOARD = {
  path: "",
  name: "Dashboard",

  SUSTAINABILITY_ACHIEVEMENTS: {
    path: "sustainability-achievements",
    name: "Sustainability Achievements",
  },
  PERFORMANCE_OVERVIEW: {
    path: "performance-overview",
    name: "Performance Overview",
  },
  FACILITIES_MONITORING: {
    path: "facilities-monitoring",
    name: "Facilities Monitoring",
    FACILITIES_MONITORING_ID: {
      path: ":facility_id",
      name: "Facilities Monitoring Details",
    },
  },
};

export const FACILITIES_MONITORING = {
  path: "facilities-monitoring",
  name: "Facilities Monitoring",
  FACILITIES_MONITORING_ID: {
    path: ":facility_id",
    name: "Facilities Monitoring Details",
  },
};

export const USER = {
  USER_CREATE: { path: "create-user", name: "Add New User" },
  USER_OVERVIEW: {
    path: "user",
    name: "User Overview",
  },
};

export const PROFILE = {
  path: "profile",
  name: "Profile",
};

export const RENEWABLE_OPTIONS = {
  path: "renewable-options",
  name: "Renewable Options",

  BUY_RECS: {
    path: "buy-recs",
    name: "Buy RECs",
  },
};
