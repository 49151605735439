export enum KEYS_MAPPING {
  YEAR = "Year",
  CO2EMISSIONS = "CO2Emissions",
  TARGET_COMBINED_CO2EMISSIONS = "TargetCombinedCO2Emissions",
  MONTH = "Month",
  RENEWABLES_ENERGY_USAGE = "RenewablesEnergyUsage",
  SCOPE2_ENERGY_USAGE = "Scope2EnergyUsage",
  RENEWABLES_ENERGY_USAGE_PERCENTAGE = "RenewablesEnergyUsagePercentage",
  RENEWABLES_TARGET_PERCENTAGE = "RenewablesTargetPercentage",
}

export enum KEYS_QUERY {
  DAY = "day",
  SEVEN_DAY = "7-day",
  MONTH = "month",
  MONTH_TO_DATE = "mtd",
  YEAR_TO_DATE = "ytd",
}

export const PerformanceOverviewRenewable = {
  SelfGenerated: {
    label: "Self Generated",
    color: "#c9e0ba",
  },
  PowerPurchaseAgreement: {
    label: "PPA",
    color: "#5d8f3b",
  },
  RecPurchased: {
    label: "Rec Purchased",
    color: "#384c29",
  },
};

export const PerformanceOverviewConsumed = {
  GridConsumption: {
    label: "Grid Consumption",
    color: "#ed7d31",
  },
  Renewables: {
    label: "Renewables",
    color: "#f8cfb2",
  },
};
